body {
    background: rgb(255, 255, 255);
    font-size: 12px;
  }

  h1 {
    color: #eee;
    font: 30px Arial, sans-serif;
    text-shadow: 0px 1px black;
    text-align: center;
  }

  input[type=checkbox] {
    visibility: hidden;
  }

  .containerbus {
    display: flex;
    justify-content: center;
  }

  .autobus {
    background: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 320px;
    height: 500px; */
    /* max-width: 350px; */
    padding: 20px;
  }

  .fila {
    display: flex;
    justify-content: space-between;
  }

  .seccion {
    display: flex;
    width: 40%;
    justify-content: space-between;
  }

  .asientosleeper {
    width: 30px;
    height: 45px;
    color: rgb(18, 18, 18);
    font-size: 10;
    text-align: center;
    background: #fcfff4;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    margin: 5px auto;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    position: relative;
    border: 1px solid var(--grey);
  }

  .asientosleeper label {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 38px;
    left: 2px;
    top: 3px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 1);
    background: linear-gradient(top, #222 0%, #45484d 100%);
    border: 1px solid var(--grey);
  }

  .asientosleeper label:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 24px;
    height: 38px;
    background: #ff6600;
    background: linear-gradient(top, #0895d3 0%, #0966a8 100%);
    top: -1px;
    left: -1px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .asientosleeper label:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  .asientosleeper input[type=checkbox]:checked + label:after {
    filter: alpha(opacity=100);
    opacity: 1;}


  .asientosleeperladies {
    width: 30px;
    height: 45px;
    color: rgb(18, 18, 18);
    font-size: 10;
    text-align: center;
    background: #fcfff4;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    margin: 5px auto;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    position: relative;
    border: 1px solid #0080ff;
  }

  .asientosleeperladies label {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 38px;
    left: 2px;
    top: 3px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 1);
    background: linear-gradient(top, #222 0%, #45484d 100%);
    border: 1px solid #0080ff;
  }

  .asientosleeperladies label:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 24px;
    height: 38px;
    background: #ff6600;
    background: linear-gradient(top, #0895d3 0%, #0966a8 100%);
    top: -1px;
    left: -1px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .asientosleeperladies label:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  .asientosleeperladies input[type=checkbox]:checked + label:after {
    filter: alpha(opacity=100);
    opacity: 1;}


  .asientosleeperbooked {
    width: 30px;
    height: 45px;
    color: rgb(18, 18, 18);
    font-size: 10;
    text-align: center;
    background: #676767;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    margin: 5px auto;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    position: relative;
    border: 1px solid #676767;
  }

  .asientosleeperbooked label {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 38px;
    left: 2px;
    top: 3px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 1);
    background: linear-gradient(top, #222 0%, #45484d 100%);
    border: 1px solid #676767;
  }

  .asientosleeperbooked label:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 24px;
    height: 38px;
    background: #ff6600;
    background: linear-gradient(top, #0895d3 0%, #0966a8 100%);
    top: -1px;
    left: -1px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .asientosleeperbooked label:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  .asientosleeperbooked input[type=checkbox]:checked + label:after {
    filter: alpha(opacity=100);
    opacity: 1;}


  .asiento {
    width: 30px;
    height: 30px;
    color: rgb(39, 39, 39);
    font-size: 10;
    text-align: center;
    background: #fcfff4;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    margin: 5px auto;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    position: relative;
    border: 1px solid var(--blue);
  }

  .asiento label {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 2px;
    top: 3px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 1);
    background: linear-gradient(top, #222 0%, #45484d 100%);
    border: 1px solid var(--blue);
  }

  .asiento label:after {
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background: #ff6600;
    background: linear-gradient(top, #0895d3 0%, #0966a8 100%);
    top: -1px;
    left: -1px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .asiento label:hover::after {
    filter: alpha(opacity=30);
    opacity: 0.9;
  }

  .asiento input[type=checkbox]:checked + label:after {
    filter: alpha(opacity=100);
    opacity: 1;}

  .l-berth{
    width: 40px;
    height: 75px;
    padding-top: 24px;
  }

  .l-seater{
    width: 40px;
    height: 42px;
    padding-top: 7px;
  }

  .seat-bg{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .theme-light .bg-theme2{
    background-color: #ff6600 !important;
  }